import { ref, watch, computed } from "@vue/composition-api";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default function useEmergencyDegreeList() {
  // Use toast
  const toast = useToast();
  const { t } = useI18nUtils();

  const refEmergencyDegreeListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", sortable: true },
    { key: "name", label: t("Name"), sortable: true },
    { key: "degree", label: t("Degree"), sortable: true },
    { key: "active", label: t("Is active") , sortable: true},
    { key: "actions", label: t("Actions") },
  ];
  const perPage = ref(10);
  const totalEmergencyDegree = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refEmergencyDegreeListTable.value
      ? refEmergencyDegreeListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEmergencyDegree.value,
    };
  });

  const refetchData = () => {
    refEmergencyDegreeListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const fetchEmergencyDegree = (ctx, callback) => {
    instance
      .get("/parameters/get-emergency-degrees/", {
        params: {
          page: currentPage.value,
          search: searchQuery.value,
          sort: sortBy.value,
          sortDir: isSortDirDesc.value,
        },
      })
      .then((response) => {
        callback(response.data.results);
      })
      .catch((err) => {

        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;

        toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: error ?? "An error has occurred. Please try again",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchEmergencyDegree,
    tableColumns,
    perPage,
    currentPage,
    totalEmergencyDegree,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEmergencyDegreeListTable,
    refetchData,
  };
}
